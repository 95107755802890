/**
 *
 * Notifications reducer
 * @author Chad Watson
 *
 */

import { useRegisterAppNotification } from "hooks/use-app-notifications/use-app-notifications";
import { OrderedMap } from "immutable";
import { relayEnvironment } from "relay-environment";
import { AppNotificationType } from "securecom-graphql/client";
import {
  CONNECTION_TIMEOUT,
  RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR,
  USER_CODE_SUBMITTED,
} from "store/systems/constants";
import { RECIEVE_ARMED_STATUS_ERROR } from "store/systems/constants/arming";
import messages from "./messages";

export const initialState = OrderedMap();

export default function reducer(state = initialState, action) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const registerAppNotification = useRegisterAppNotification(relayEnvironment);

  switch (action.type) {
    case CONNECTION_TIMEOUT:
      registerAppNotification({
        id: messages.connectionTimeout.id,
        type: AppNotificationType.ERROR,
        message: messages.connectionTimeout.defaultMessage,
        autoDismiss: true,
      });
      return state;
    case RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR:
      registerAppNotification({
        type: AppNotificationType.ERROR,
        message: action.errorMessage.defaultMessage,
        autoDismiss: true,
      });
      return state;
    case RECIEVE_ARMED_STATUS_ERROR:
      // we don't want to dispatch a notification if it is from admin arming because
      // these errors are generic and often delayed more than would be ideal
      action.fromAdminArming &&
        registerAppNotification({
          type: AppNotificationType.ERROR,
          message: action.error.defaultMessage,
          autoDismiss: true,
        });
      return state;
    case USER_CODE_SUBMITTED:
      return state.has(RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR)
        ? state.delete(RECEIVE_INITIALIZE_SYSTEM_SESSION_ERROR)
        : state;
    default:
      return state;
  }
}
