/**
 *
 * Date utility functions
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import { setDate, setMonth } from "date-fns/esm/fp";
import { Map, Seq } from "immutable";
import moment from "moment";
import {
  add,
  compose,
  curry,
  curryN,
  invoker,
  nth,
  prop,
  slice,
  __,
} from "ramda";
import { toInt } from "utils";
import timezones from "utils/timezones.json";

const ONE_MINUTE = 60 * 1000;

const getTimezoneOffset = compose(
  Maybe.fromNullable,
  prop(
    __,
    Seq(timezones)
      .reduce(
        (acc, timezone) =>
          timezone.utc.reduce(
            (subAcc, utc) => subAcc.set(utc, timezone.offset),
            acc
          ),
        Map()
      )
      .toObject()
  )
);
export const safeMoment = (x) => {
  const date = moment(x);
  return date.isValid() ? Maybe.Just(date) : Maybe.Nothing();
};
export const asUtc = (date) =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
export class Now {
  static stringRepresentsNow = (x) => x === "now()";
  static isNow = (x) => x instanceof Now;
  static parse = (x) =>
    Now.stringRepresentsNow(x) ? Maybe.Just(new Now()) : Maybe.Nothing();

  constructor() {
    this.$value = () => moment.utc();
  }

  fork() {
    return this.$value();
  }

  toString() {
    return "now()";
  }
}
export class DaysFromNow {
  static pattern = /^now\((-?[0-9]+)\)$/;
  static stringRepresentsDaysFromNow = (x) => DaysFromNow.pattern.test(x);
  static isDaysFromNow = (x) => x instanceof DaysFromNow;
  static isXDaysFromNow = curryN(
    2,
    (x, y) => DaysFromNow.isDaysFromNow(y) && y.$offset === x
  );
  static parseOffset = (x) =>
    Maybe.fromNullable(x)
      .chain((s) => Maybe.fromNullable(s.match(DaysFromNow.pattern)))
      .chain(compose(Maybe.fromNullable, nth(1)))
      .map(toInt);
  static parse = (x) =>
    DaysFromNow.parseOffset(x).map((offset) => new DaysFromNow(offset));

  constructor(offset) {
    this.$offset = offset;
  }

  fork() {
    return new Now().fork().add(this.$offset, "days");
  }

  toString() {
    return `now(${this.$offset.toString()})`;
  }
}
export class Today {
  static stringRepresentsToday = (x) =>
    x === "today()" || x === "today_local()";
  static isToday = (x) => x instanceof Today;
  static parse = (x) =>
    Today.stringRepresentsToday(x) ? Maybe.Just(new Today()) : Maybe.Nothing();

  constructor() {
    this.$value = () => moment.utc().startOf("day");
  }

  fork() {
    return this.$value();
  }

  toString() {
    return "today()";
  }

  toStringLocal() {
    return "today_local()";
  }
}
export class DaysFromToday {
  static pattern = /^today\((-?[0-9]+)\)$/;
  static localPattern = /^today_local\((-?[0-9]+)\)$/;
  static stringRepresentsDaysFromToday = (x) =>
    DaysFromToday.pattern.test(x) || DaysFromToday.localPattern.test(x);
  static isDaysFromToday = (x) => x instanceof DaysFromToday;
  static isXDaysFromToday = curryN(
    2,
    (x, y) => DaysFromToday.isDaysFromToday(y) && y.$offset === x
  );
  static parseOffset = (x) =>
    Maybe.fromNullable(x)
      .chain((s) =>
        Maybe.fromNullable(s.match(DaysFromToday.pattern)).orElse(() =>
          Maybe.fromNullable(s.match(DaysFromToday.localPattern))
        )
      )
      .chain(compose(Maybe.fromNullable, nth(1)))
      .map(toInt);
  static parse = (x) =>
    DaysFromToday.parseOffset(x).map((offset) => new DaysFromToday(offset));

  constructor(offset) {
    this.$offset = offset;
  }

  fork() {
    return new Today().fork().add(this.$offset, "days");
  }

  toString() {
    return `today(${this.$offset.toString()})`;
  }

  toStringLocal() {
    return `today_local(${this.$offset.toString()})`;
  }
}
export const lessThanXMinutesAgo = ({ oldDate, newDate, x }) =>
  newDate - oldDate < x * ONE_MINUTE;
export const today = () => new Date();
export const yesterday = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};
export const seconds = (amount) => amount * 1000;
export const minutes = (amount) => amount * seconds(60);
export const parseDate = moment.utc;
export const todayStart = () => moment().utc().startOf("day");
export const todayEnd = () => moment().utc().endOf("day");
export const yesterdayStart = () =>
  moment().utc().subtract(1, "day").startOf("day");
export const yesterdayEnd = () =>
  moment().utc().subtract(1, "day").endOf("day");
export const thisWeekStart = () => moment().utc().startOf("week");
export const thisWeekEnd = () => moment().utc().endOf("week");
export const lastWeekStart = () =>
  moment().utc().subtract(1, "week").startOf("week");
export const lastWeekEnd = () =>
  moment().utc().subtract(1, "week").endOf("week");
export const thisMonthStart = () => moment().utc().startOf("month");
export const thisMonthEnd = () => moment().utc().endOf("month");
export const lastMonthStart = () =>
  moment().utc().subtract(1, "month").startOf("month");
export const lastMonthEnd = () =>
  moment().utc().subtract(1, "month").endOf("month");
export const setOffset = curry((offset, date) => {
  const utc = date.getTime() + date.getTimezoneOffset() * 60000;
  return new Date(utc + 3600000 * offset);
});
export const changeTimezone = curry((timezone, date) =>
  getTimezoneOffset(timezone).map((offset) => setOffset(offset, date))
);
const toJanFirst = compose(setDate(1), setMonth(1));
const toJunFirst = compose(setDate(1), setMonth(5));
export const getDstBias = (date) =>
  Math.abs(
    toJunFirst(date).getTimezoneOffset() - toJanFirst(date).getTimezoneOffset()
  );
export const removeDst = (date) =>
  setOffset(-(date.getTimezoneOffset() + getDstBias(date)) / 60, date);
export const makeDate = (...args) => new Date(...args);
export const toISOString = invoker(0, "toISOString");
export const getISODateString = (date) =>
  `${date.getFullYear()}-${
    (date.getMonth() + 1).toString().length < 2 ? "0" : ""
  }${date.getMonth() + 1}-${
    date.getDate().toString().length < 2 ? "0" : ""
  }${date.getDate()}`;
export const getUTCDateString = (date) =>
  `${date.getUTCFullYear()}-${
    (date.getUTCMonth() + 1).toString().length < 2 ? "0" : ""
  }${date.getUTCMonth() + 1}-${
    date.getUTCDate().toString().length < 2 ? "0" : ""
  }${date.getUTCDate()}`;
export const getUTCDateTimeString = (date) =>
  `${getUTCDateString(date)}T` +
  `${date.getUTCHours().toString().padStart(2, "0")}:` +
  `${date.getUTCMinutes().toString().padStart(2, "0")}:` +
  `${date.getUTCSeconds().toString().padStart(2, "0")}Z`;
export const getYearsFromIsoString = compose(toInt, slice(0, 4));
export const getMonthsFromIsoString = compose(add(-1), slice(5, 7));
export const getDateFromIsoString = compose(toInt, slice(8, 10));
export const getHoursFromIsoString = compose(toInt, slice(11, 13));
export const getMinutesFromIsoString = compose(toInt, slice(14, 16));
export const getSecondsFromIsoString = compose(toInt, slice(17, 19));
export const createDateFromIsoString = (dateString) =>
  new Date(
    Date.UTC(
      getYearsFromIsoString(dateString),
      getMonthsFromIsoString(dateString),
      getDateFromIsoString(dateString),
      getHoursFromIsoString(dateString),
      getMinutesFromIsoString(dateString),
      getSecondsFromIsoString(dateString)
    )
  );
export const isValidDate = (date) =>
  date instanceof Date && !isNaN(date.valueOf());
export const safeDateFromIsoString = (dateString) =>
  Maybe.fromNullable(dateString)
    .chain((value) =>
      value ? Maybe.of(createDateFromIsoString(value)) : Maybe.Nothing()
    )
    .chain((date) => (isValidDate(date) ? Maybe.of(date) : Maybe.Nothing()));
export const getFormattedUtcDate = (date) =>
  `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
export const getFormattedUtcDateWithoutYear = (date) =>
  `${date.getUTCMonth() + 1}/${date.getUTCDate()}`;
export const getFormattedUtcHours = (date) =>
  `${
    date.getUTCHours() >= 11
      ? date.getUTCHours() - 12
      : date.getUTCHours() === 0
      ? 12
      : date.getUTCHours()
  }`;
export const getFormattedUtcMinutes = (date) =>
  `${
    date.getUTCMinutes() < 10
      ? `0${date.getUTCMinutes()}`
      : date.getUTCMinutes()
  }`;
export const getFormattedUtcTime = (date) =>
  `${getFormattedUtcHours(date)}:${getFormattedUtcMinutes(date)} ${
    date.getUTCHours() >= 11 ? "PM" : "AM"
  }`;
export const getFormattedUtcDateAndTime = (date) =>
  `${getFormattedUtcDate(date)} ${getFormattedUtcTime(date)}`;

export const createISOStringWithNoOffset = (date) =>
  `${date.toISOString().slice(0, 19)}.000Z`;

export const createLocalDateFromAbsoluteIsoString = (dateString) =>
  new Date(
    getYearsFromIsoString(dateString),
    getMonthsFromIsoString(dateString),
    getDateFromIsoString(dateString),
    getHoursFromIsoString(dateString),
    getMinutesFromIsoString(dateString),
    getSecondsFromIsoString(dateString)
  );

const isShortDateString = (dateString) =>
  /([0-2][0-9]|3[01])(0[0-9]|1[0-2])([0-9][0-9])/.test(dateString);

/**
 * Format a zulu datestring into a standard Date as a string.
 * Also useful for removing unintended offsets.
 * @param dateString
 * @returns Date
 */
export const dateFromDateString = (dateString) => {
  if (isShortDateString(dateString)) {
    return new Date(
      `20${dateString.slice(4, 6)}`,
      dateString.slice(2, 4) - 1,
      dateString.slice(0, 2)
    );
  } else {
    //use zulu date to avoid timezone issues and remove the z at the end
    const date = new Date(dateString);
    const result = new Date(date.toISOString().slice(0, -1));
    return result;
  }
};

export const stripZulu = (zuluDate) => {
  const date = new Date(zuluDate);
  const result = new Date(date.toISOString().slice(0, -1));
  return result;
};
