import {
  always,
  anyPass,
  both,
  complement,
  compose,
  cond,
  curry,
  either,
  head,
  ifElse,
  prop,
  T,
} from "ramda";
import { memoizeLatestBy } from "utils";

const hasHardwareModel = curry(
  (hardwareModel, system) => system.panels?.[0].hardware_model === hardwareModel
);

const hasArmingSystem = curry(
  (armingSystem, system) => system.panels?.[0].arming_system === armingSystem
);

export const isXt30 = hasHardwareModel("XT30");
export const isXt30L = hasHardwareModel("XT30L");
export const isXt50 = hasHardwareModel("XT50");
export const isXt50L = hasHardwareModel("XT50L");
export const isXtl = hasHardwareModel("XTL");
export const isXtlN = hasHardwareModel("XTLN");
export const isXtlW = hasHardwareModel("XTLW");
export const isXtlPlus = hasHardwareModel("XTLP");
export const isXr150 = hasHardwareModel("XR150");
export const isXr350 = hasHardwareModel("XR350");
export const isXr550 = hasHardwareModel("XR550");
export const isXf100 = hasHardwareModel("XF6_100");
export const isXf500 = hasHardwareModel("XF6_500");
export const isXt75 = hasHardwareModel("XT75");
export const isTmSentry = hasHardwareModel("TMS6");
export const isCellComSl = hasHardwareModel("CellComSL");
export const isIComSl = hasHardwareModel("iComSL");
export const isDualCom = hasHardwareModel("DualCom");
export const isMiniCellCom = hasHardwareModel("MiniCellCom");
export const isVideoOnly = hasHardwareModel("Video Only");
export const isX1 = hasHardwareModel("X001");
export const isCellComEx = hasHardwareModel("CellComEX");
export const isBillingControlSystem = (system) =>
  system.site_billing_control_system;
export const hasUniviewEnabled = (system) => system.univiewEnabled;

export const isXr = anyPass([isXr150, isXr350, isXr550]);

export const isXf = anyPass([isXf100, isXf500]);

export const isXT75 = anyPass([isXt75]);

export const isTMSentry = anyPass([isTmSentry]);

export const isXt = anyPass([
  isXt30,
  isXt30L,
  isXt50,
  isXt50L,
  isXtlPlus,
  isXtl,
  isXtlN,
  isXtlW,
  isCellComSl,
  isIComSl,
  isDualCom,
  isMiniCellCom,
  isCellComEx,
]);

export const isTakeoverPanel = anyPass([
  isCellComSl,
  isIComSl,
  isDualCom,
  isMiniCellCom,
  isCellComEx,
]);

export const isAPSystem = hasArmingSystem("AP");
export const isHASystem = hasArmingSystem("HA");
export const isHSASystem = hasArmingSystem("HSA");
export const isHSAGSystem = either(
  hasArmingSystem("HSAG"),
  hasArmingSystem("HSAWG")
);
export const isAreaSystem = both(
  hasArmingSystem("AREA"),
  complement(isMiniCellCom)
);

const hasKeypadInput = curry(
  (target, controlSystem) =>
    controlSystem.panels[0]?.system_options[0]?.kpad_input?.toUpperCase() ===
    target
);

export const isECP = hasKeypadInput("E");
export const isDSC = hasKeypadInput("D");

export const minFirmware = curry(
  (minVersion, system) => system.panels[0].software_version >= minVersion
);

export const minUserCodeNumber = isXf ? always(1) : always(2);

export const maxUserCodeNumber = cond([
  [anyPass([isXr, isX1]), always(9999)],
  [both(isTakeoverPanel, minFirmware(194)), always(99)],
  [anyPass([isECP, isDSC, isIComSl, isCellComSl]), always(20)],
  [anyPass([isXt50, isXtlPlus]), always(99)],
  [anyPass([isXf]), always(10)],
  [anyPass([isXT75]), always(200)],
  [T, always(30)],
]);

export const availableUserCodeNumbers = memoizeLatestBy(
  prop("id"),
  (controlSystem) => {
    const takenNumbers = new Set(
      controlSystem.panels[0].user_codes?.map(({ number }) => Number(number)) ??
        []
    );
    const min = minUserCodeNumber(controlSystem);
    const max = maxUserCodeNumber(controlSystem);
    const availableNumbers = [];

    for (let number = min; number <= max; number++) {
      if (!takenNumbers.has(number)) {
        availableNumbers.push(number);
      }
    }

    return availableNumbers;
  }
);

export const firstAvailableUserCodeNumber = compose(
  head,
  availableUserCodeNumbers
);

export const supportsPrivateDoors = anyPass([
  both(isXr, minFirmware(202)),
  isXt75,
]);

export const supportsTempUserOnUserCode = anyPass([
  both(isXr, minFirmware(191)),
  isXt75,
  isTmSentry,
]);

export const supportsWifiSetup = anyPass([
  both(isXr, minFirmware(112)),
  isXt75,
  isTmSentry,
]);

export const supportsSendCodesToLocks = anyPass([
  both(isXt, minFirmware(171)),
  isXt75,
]);

export const supportsInactiveUser = anyPass([
  both(isXr, minFirmware(172)),
  isX1,
  isXt75,
  isTmSentry,
]);

export const maxUserCodeNameLength = ifElse(
  anyPass([isXr, isXf, isXT75]),
  always(32),
  always(16)
);

export const hasLegacyTempUser = both(
  isXr,
  complement(supportsTempUserOnUserCode)
);
