import { defineMessages } from "react-intl";

export default defineMessages({
  failedToUpdateAreaSettings: {
    id: "app.stores.systems.users.failedToUpdateAreaSettings",
    defaultMessage: "An error occurred while updating area settings.",
  },
  failedToDeleteSchedule: {
    id: "app.stores.systems.users.failedToDeleteSchedule",
    defaultMessage: "An error occurred while deleting the schedule.",
  },
  failedToUpdateDoor: {
    id: "app.stores.systems.users.failedToUpdateDoor",
    defaultMessage: "Cannot change door status",
  },
});
